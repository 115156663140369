
.white-content {
  .footer{
    .nav-link {
      color: $primary;
    }
  }
}

.white-content {
  .enroll-security-key-page {
    .footer{
      .nav-link, .copyright {
        color: #fff!important;
      }
    }
  }
}

.envelope-page {
  .footer {
    .copyright, .nav-link {
      color: #fff!important;
    }
  }
}